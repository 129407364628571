<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<span class="mr20"><b>今日统计:</b></span>
				<span class="inlineBlock mr20">总金额：<span class="cdanger">{{countAll.total_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">成功金额：<span class="cdanger">{{countAll.success_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">总笔数：<span class="cdanger">{{countAll.total_count}}</span></span>
				<span class="inlineBlock mr20">成功笔数：<span class="cdanger">{{countAll.success_count}}</span></span>
			</div>
			<div class="mb20">
				<el-select v-model="state" placeholder="请选择订单状态" size="mini" class="mr20" clearable>
					<el-option key="" label="全部" value=""></el-option>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<el-select v-model="payType" placeholder="请选择支付方式" size="mini" class="mr20" clearable>
					<el-option key="" label="全部" value=""></el-option>
					<el-option v-for="item in payTypeArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-input size="mini" placeholder="请输入商户订单号" v-model="order_id" clearable class="mr20" style="width: 200px"></el-input>
			
				<el-button size="mini" plain class="mr20" @click="setDate(-1)":disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)":disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">查询</el-button>
				<el-button size="mini" type="primary" :disabled="tableData.length==0" icon="el-icon-document" @click="getExcel">导出Excel</el-button>
			</div>
		</div>
		<template>
			<el-table size="mini" :data="tableData" border="" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column prop="order_id" label="商家订单编号" width="100px" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							{{scope.row.order_id}}
						</div>
						<div class="textCenter" v-if="!scope.row.id">
							<span v-if="scope.$index == tableData.length - 2">单页合计</span>
							<span v-if="scope.$index == tableData.length - 1">总计</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="water_no" label="平台交易号" width="170px" align="center"></el-table-column>
				<el-table-column label="支付方式" width="180px" align="center">
					<template slot-scope="scope">
						<div class="textCenter">
							<el-link type="primary" v-if="scope.row.true_name||scope.row.bank || scope.row.card">
								{{scope.row.true_name}} | {{scope.row.bank}}
								<br>
								{{scope.row.card}}
							</el-link>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="通道名称" prop="pay_type_name" align="center"></el-table-column>
				<el-table-column label="创建时间" align="center">
					<template slot-scope="scope">
						<div class="textCenter">
							{{scope.row.add_time}}
						</div>
				
					</template>
				</el-table-column>
				<el-table-column label="成功时间" align="center">
					<template slot-scope="scope">
						<div class="textCenter">
							{{scope.row.action_time}}
						</div>
				
					</template>
				</el-table-column>
				<el-table-column label="提交金额(元)" align="center">
					<template slot-scope="scope">
						{{scope.row.add_gold |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="实际金额(元)" align="center">
					<template slot-scope="scope">
						{{scope.row.gold | tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="交易状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.state==1?'csuccess':scope.row.state==7?'cdanger':''">{{stateArr[scope.row.state]}}</span>
						
					</template>
				</el-table-column>
				<el-table-column label="Fee ‰" align="center">
					<template slot-scope="scope">
						{{scope.row.fee_gold_tra | tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="实际到账(元)" align="center">
					<template slot-scope="scope">
						{{scope.row.trader_gold | tofixed}}
					</template>
				</el-table-column>
				
			
			</el-table>
		</template>

		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>


	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				state: '',
				stateArr: {},
				payType: '',
				payTypeArr: [],
				tableData: [],				
				order_id: '',
				date: null,
				countAll: {
					total_money:0,
					success_money:0,
					total_count:0,
					success_count:0,
				},
				
				
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				
				
				// water_no: '',
				// business_no: '',
				// card_name: '',
				// business_name: '',
				// add_gold: '',
				// req_ip: '',
				// pay_type: '',
				// paywayArr: [],
				// stateMap:{},
				
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			
			this.stateList()
			this.payTypeList()
			this.orderInList()
			this.orderInTotal()

		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			
			stateList() {
				this.$api.stateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			payTypeList() {
				this.$api.payType({hasRepay: 2}).then(res => {
					if (res.status === 1) {
						this.payTypeArr = res.data.data
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			
			
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderInList();
			},
			//总计
			orderInTotal() {
				this.$api.orderInTotal({}).then(res => {
					console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//导出Excel
			getExcel() {
				let username = local.get('shusername');
				let tid = local.get('shtid')
				let start_time=this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '';
				let end_time=this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '';
				console.log(username,tid)
				let href =
					`${this.$baseURL}/trader/order/exportList?username=${username}&tid=${tid}&order_id=${this.order_id}&pay_type=${this.payType}&state=${this.state}&start_time=${start_time}&end_time=${end_time}`
				window.location.href = href
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderInList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderInList()
			},
			

			orderInList() {
				let data = {
					order_id: this.order_id,
					pay_type: this.payType,
					state: this.state,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					page: this.page,
					size: this.pageSize,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.orderInList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			
		}
	};
</script>

<style>
</style>
